/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/react-in-jsx-scope */
import PIModal from "./premier-inn-modal";
import SHModal from "./south-lodge-modal";
import TLModal from "./travelodge-modal";

const accommodationSection = () => {
  return (
    <div className="grid grid-rows-3 bg-blue-900 hover:bg-blue-800">
      <div className="content-center w-full p-5 rounded-xl">
        <div className="content-center p-3 text-3xl font-semibold text-white underline">Accommodation Options</div>
      <div className="p-3 text-xl italic text-white">Click on the logo's for more information</div>
      </div>
      <div className="grid grid-cols-3">
        <div className="flex flex-auto text-white text-xl justify-center">
          {SHModal()}
        </div>
        <div className="flex flex-auto justify-center text-white text-xl">
          {PIModal()}
        </div>
        <div className="flex flex-auto justify-center text-white text-xl">
          {TLModal()}
        </div>
      </div>
    </div>
  )
};

export default accommodationSection;