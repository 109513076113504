/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/react-in-jsx-scope */
const informationSection = () => {
  return (
    <div className="grid bg-blue-900 hover:bg-blue-800">
      <div className="content-center p-5 rounded-xl sm:w-full  md:w-9/12 justify-self-center">
        <div className="content-center p-7 text-3xl font-semibold text-white underline">Important Information</div>
          <div className="grid grid-cols-2">
          <div className="flex flex-auto justify-center text-white text-xl">
            <ul className="list-none">
              <li className="p-5 flex flex-auto">
                We’d love our guests to have a good time without worrying about little eyes and ears, so we politely request no children
              </li>
              <li className="p-5 flex flex-auto">
                We will kindly ask guests to refrain from posting on social media any photo's or videos from the day. We have hired professionals so you can enjoy the moment!
              </li>
            </ul>
          </div>
          <div className="flex flex-auto justify-center text-white text-xl">
            <ul className="list-none">
              <li className="p-5 flex flex-auto">
                Please make sure you have transport from the Church to the Reception at South Lodge, it is a 20min drive
              </li>
              <li className="p-5 flex flex-auto">
                If you have any dietary requirements please make sure you make us aware by filling out the Dietary requirements and Allergies section on the RSVP
              </li>
            </ul>
          </div>
        </div>
          <div className="p-5 text-xl justify-self-center">
            <div className="p-5 flex flex-wrap text-center justify-center font-bold text-white text-2xl">
              Wedding Gifts
            </div>
            <div className="italic text-white text-xl w-auto">
              Your presence at our wedding is the best present of all.
              <br></br>However, if you’d like to give us a gift, we would appreciate contributions towards our honeymoon fund.
              <br></br>
              <br></br>
                <a href="https://honeymoon.hemsley-wedding.com" target="_blank" rel="noreferrer" className="bg-teal-500 hover:bg-blue-400 hover:text-white text-white font-bold py-4 px-6 rounded">I'd like to contribute!</a><br></br>
            </div>
          </div>
      </div>
    </div>
  )
};

export default informationSection;