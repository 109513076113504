/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import CountdownTimer from './lib/countdown-timer';
import churchSection from './lib/church-section';
import venueSection from './lib/venue-section';
import accommodationSection from './lib/accommodation-section';
// import ContactForm from './lib/contact-form';
import informationSection from './lib/information-section';
import { BiSolidChevronsDown } from "react-icons/bi";


function App() {
  return (
    <div className="App">
      <header
        className="relative items-center mb-6">
        <div className="grid grid-rows-4 md:grid-rows-5 w-auto justify-items-center">
          <div className="row-start-1 m-5 z-10 p-5 bg-blue-wedding bg-opacity-75 rounded-xl">
          {/* <div className="content-center z-10">
            <img src='/ajlogo.png' alt='logo' className="w-3/4 mx-auto p-5 z-10"></img>
          </div> */}
            <div>
              {CountdownTimer()}
            </div>
          </div>
          <div className="row-start-4 md:row-start-5 text-white font-bold text-5xl animate-bounce z-20">
            <BiSolidChevronsDown />
          </div>

        <video
          className="absolute z-0 w-full h-full object-cover"
          src={"https://www.hemser.com/data/EgVid.mov"}
          muted autoPlay loop playsInline>
        </video>
      </div>
      </header>
      <div>
        <h1 className="text-black p-2 text-5xl font-bold italic">Welcome to our Wedding!</h1>
        <h1 className="text-black p-2 font-bold italic text-4xl">Saturday 25th May 2024</h1>
      </div>
      <div className="p-2"></div>
        {churchSection()}
      <div className="p-2"></div>
        {venueSection()}
      <div className="p-2"></div>
        {accommodationSection()}
      <div className="p-2"></div>
        {informationSection()}
      {/* <div className="p-2"></div>
        {ContactForm()} */}
    </div>
  );
}

export default App;
