/* eslint-disable react/react-in-jsx-scope */
const venueSection = () => {
  return (
    <div className="grid md:grid-cols-2 gap-2 sm:grid-cols-1 bg-blue-900 hover:bg-blue-800">
      <div className="hidden md:block content-center w-full">
        <iframe
          title="ChurchMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5020.073567269925!2d-0.26573645093874343!3d51.01546833367627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875eb61421a3e17%3A0x3bce0deb5b2da142!2sSouth%20Lodge!5e0!3m2!1sen!2suk!4v1691100322087!5m2!1sen!2suk"
          className="w-full h-full aspect-auto"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
          </iframe>
      </div>
      <div className="content-center w-full p-5 rounded-xl">
        <div className="content-center p-5 text-3xl font-bold text-white underline">Reception</div>
        <img src='/slhfront.png' alt='church' className="w-2/3 mx-auto p-3"></img>
        <div className="content-center text-xl text-white p-2">South Lodge Hotel & Spa, Brighton Road, Lower Beeding, RH13 6PS</div>
        <div className="p-2 content-center text-xl text-white font-bold">Drinks Reception Upon Arrival</div>
        <div className="content-center text-white p-2"><b>Please Note:</b> South Lodge have ample parking and vehicles may be left overnight.</div>
        <div className="content-center text-white p-2"><b>Carriages at midnight</b></div>
      </div>
      <div className="block md:hidden content-center w-full">
        <iframe
          title="ChurchMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5020.073567269925!2d-0.26573645093874343!3d51.01546833367627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875eb61421a3e17%3A0x3bce0deb5b2da142!2sSouth%20Lodge!5e0!3m2!1sen!2suk!4v1691100322087!5m2!1sen!2suk"
          className="w-full h-full aspect-auto max-sm:aspect-square"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
          </iframe>
      </div>
    </div>
  )
};

export default venueSection;