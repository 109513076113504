/* eslint-disable react/react-in-jsx-scope */
const churchSection = () => {
  return (
    <div className="grid md:grid-cols-2 gap-2 sm:grid-cols-1 bg-blue-900 hover:bg-blue-800">
      <div className="content-center w-full p-5 rounded-xl">
        <div className="content-center p-5 text-3xl font-bold text-white underline">The Church</div>
        <img src='/stnicholas.png' alt='church' className="w-2/3 mx-auto p-3"></img>
        <div className="content-center text-xl text-white p-2">St Nicholas Church, Itchingfield, Horsham, RH13 0NX</div>
        <div className="p-2 content-center text-xl text-white font-bold">Ceremony 12.30pm</div>
        <div className="p-2 content-center text-white"><b>Please Note:</b> St Nicholas has restricted parking, we therefore ask guests to park on the side of the road near the old school. This is located on the corner near St Nicholas.</div>
      </div>
      <div className="content-center w-full">
        <iframe
          title="ChurchMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.243265291557!2d-0.3899012230910431!3d51.048595844116406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875c033e4d79c2d%3A0xd8f1748ffd64cc61!2sSt%20Nicolas%20Church!5e0!3m2!1sen!2suk!4v1691099103572!5m2!1sen!2suk"
          className="w-full h-full aspect-auto max-sm:aspect-square"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
          </iframe>
      </div>
    </div>
  )
};

export default churchSection;