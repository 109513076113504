/* eslint-disable react/no-unescaped-entities */
import React from "react";

export default function TLModal() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button
        className=" shadow hover:shadow-lg"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img src='/travel_lodge.png' alt='premier_inn' className="mx-auto p-3 justify-items-center w-auto"></img>
      </button>
      {showModal ? (
        <>
          <div className="flex flex-auto items-center content-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative content-center w-auto my-6 mx-auto max-w-screen-sm">
              <div className="border-0 rounded-lg shadow-lg content-center relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex w-auto content-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="w-full items-center text-black font-semibold content-center">
                    <img src='/travel_lodge.png' alt='church' className="w-2/3 mx-auto p-2"></img>
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                  <p className="my-4 text-black text-lg p-3 leading-relaxed">
                    There are multiple Travelodge's in the area, the closest being Horsham Central Hotel<br></br>
                    <br></br>
                    <a href="https://www.travelodge.co.uk/hotels/426/Horsham-Central-hotel" target="_blank" rel="noreferrer" className="underline hover:text-blue-600 text-blue-800">Horsham Central Hotel</a><br></br>
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}